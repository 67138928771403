import React from "react"
import logoSvg from "../../images/dv-logo.svg"
import { Flex, Box, Heading, Image, Text, Grid } from "theme-ui"
import Cta from "../cta"
import CoverImage from "../cover_image/cover_image_no_lazy"
import Richtext from "../richtext"
import Section from "../section"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
} from "react-instantsearch-hooks-web"

const searchClient = algoliasearch(
  "WMAEPUK730",
  "75deb033e50e07644873a2beb8128ac0"
)

function Hit({ hit }) {
  return (
    <Grid columns={2} gap={4} mb={4}>
      <Box>
        <Image src={hit.image} />
      </Box>
      <Box>
        <Heading>{hit.title}</Heading>
        <Text>{hit.description}</Text>
      </Box>
    </Grid>
  )
}

const NotFound = ({ content }) => {
  return (
    <>
      <CoverImage
        image={content.image}
        factor="0.5"
        variant="gradientFull"
        sx={{ position: "fixed", zIndex: "10", height: "100vh" }}
      />

      <Section>
        <Flex
          //backgroundColor="#000"
          color="#fff"
          sx={{
            flexDirection: "column",
            height: "100%",
            my: [5, null, 6],
            zIndex: "999",
            position: "relative",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: ["120px", "160px"],
              mb: [4, null, null, 6],
              //mt: [0, null, 4],
              alignSelf: "center",
            }}
          >
            <Image
              src={logoSvg}
              alt="Donkervoort logo"
              sx={{ width: "100%" }}
            />
          </Box>

          <Flex
            sx={{
              justifyContent: "flex-end",
              mt: ["5vh", 0],
            }}
          >
            <Box
              sx={{ width: ["100%", null, "50%"] }}
              mx={[0, 5, 0]}
              mt={[4, null, null, 0]}
            >
              <Heading
                as="h1"
                fontSize={[7, 7, 8, 9]}
                mb="3"
                sx={{ maxWidth: "15ch" }}
              >
                {/* <Heading
                    as="span"
                    sx={{ display: "block", fontSize: [9, 9, 9] }}
                  >
                    404
                  </Heading> */}
                <span>{content.title}</span>
              </Heading>
              <Richtext
                text={content.text}
                as="div"
                sx={{ maxWidth: "60ch" }}
              />
              <Box>
                <Flex
                  sx={{
                    flexDirection: ["column", "row"],
                    flexWrap: "wrap",
                    "& > a > button": {
                      mb: 3,
                      pt: 3,
                      pb: 3,
                      pr: "40px",
                      pl: "40px",
                      mr: [0, 3],
                      minWidth: ["100%", "200px"],
                    },
                  }}
                >
                  {content.homepage_link && (
                    <Cta
                      key={content.homepage_link._uid}
                      blok={{
                        component: "cta",
                        text: content.homepage_link_text,
                        link: content.homepage_link,
                      }}
                      variant="secondary"
                    />
                  )}
                  {content.contact_link && (
                    <Cta
                      key={content.contact_link._uid}
                      blok={{
                        component: "cta",
                        text: content.contact_link_text,
                        link: content.contact_link,
                      }}
                      variant="outlineWhite"
                    />
                  )}
                </Flex>
              </Box>
            </Box>
          </Flex>
          {/* <InstantSearch
            searchClient={searchClient}
            indexName="netlify_c32193de-12d1-493b-816c-4dbf44cf933e_master_all"
          >
            <Configure queryLanguages={["nl"]} hitsPerPage={20} />
            <SearchBox placeholder="Search" />
            <Hits hitComponent={Hit} />
          </InstantSearch> */}
        </Flex>
      </Section>
    </>
  )
}

export default NotFound
